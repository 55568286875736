











































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import PackageRadioSelect from '@/components/PackageRadioSelect.vue'
import Sale from '@/components/_uikit/Sale.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterCoursesModule from '@/store/modules/master/courses'
import MasterOrdersModule from '@/store/modules/master/orders'
// types
import { CourseType, MasterOrderLargeResource, PurchaseLargeResource } from '@/store/types'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Dialog,
    FilesList,
    ModalWrapper,
    PackageRadioSelect,
    Sale,
  },
})
export default class CoursePaymentModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: 0 })
  private groupTypeIndex!: number

  @Prop({ default: false })
  private isSale!: boolean

  @Prop({ required: true })
  private courseID!: number

  private localVisible = this.visible

  private course: PurchaseLargeResource | null = null
  private selectedGroupIndex = this.groupTypeIndex
  private previousPackages = new Map()
  private packageId = this.previousPackages?.get(this.selectedGroupIndex) ?? 0

  private get self() {
    return AuthModule.self
  }

  private get courseInfo() {
    return MasterOrdersModule.offer?.info
  }

  private get group () {
    return this.course ? this.course.groups[this.selectedGroupIndex] : null
  }

  private get isSpecial(): boolean {
    return Boolean(this.course?.type.value === CourseType.SPECIAL)
  }

  private get courseHash() {
    return this.$route.params.hash
  }

  private get courseEducationMonths() {
    if (this.course) {
      return {
        end: formatDate(this.course.educationEndAt, 'LLLL').toLowerCase(),
        isTogether: formatDate(this.course.educationEndAt, 'MMMM').toLowerCase() === formatDate(this.course.educationStartAt, 'MMMM').toLowerCase(),
        start: formatDate(this.course.educationStartAt, 'MMMM').toLowerCase(),
      }
    }
    return {
      end: '',
      isTogether: true,
      start: '',
    }
  }

  private mounted () {
    if (this.localVisible)
      this.fetchCourse(this.courseID)
  }

  private fetchCourse (courseID: number) {
    if(this.$route.params.hash) {
      this.setDefaultPackage(this.courseInfo)
      this.course = Object.assign({}, this.courseInfo)
    } else {
      MasterCoursesModule.fetchCourseInfo(courseID)
        .then((response: PurchaseLargeResource) => {
          this.setDefaultPackage(response)
          this.course = Object.assign({}, response)
        })
        .catch((error: any) => {
          this.handleClose()
          this.notifyError(error)
        })
    }
  }

  private handleOffer () {
    if(!this.self) {
      this.$emit('showLoginModal')
      this.$emit('close')
      return
    }

    if (!this.packageId) {
      this.notifyError('Выберите период обучения')
      return
    }

    if (this.group) {
      if (this.course && this.course.subject) {
        MasterOrdersModule.setSubject(+this.course.subject.value)
      }

      if(this.courseHash) {
        MasterOrdersModule.purchaseOffer({
          link: this.courseHash,
          params: {
            courseId: this.courseID,
            groupId: this.group.id,
            packageId: this.packageId,
          },
        })
          .then((response: MasterOrderLargeResource) => {
            if (this.courseID === 346) {
              try {
                this.$metrika.reachGoal('gotopaybasemath')
              } catch {
              // eslint-disable-next-line
              console.error('Отправка метрики заблокирована расширением браузера')
              }
            }
            this.$router.push({ name: 'master.payment.order', params: { orderUUID: response.uuid } })
          })
          .catch (this.notifyError)
      } else {
        MasterOrdersModule.createOrder({
          courseId: this.courseID,
          groupId: this.group.id,
          packageId: this.packageId,
        })
          .then((response: MasterOrderLargeResource) => {
            if (this.courseID === 346) {
              try {
                this.$metrika.reachGoal('gotopaybasemath')
              } catch {
              // eslint-disable-next-line
              console.error('Отправка метрики заблокирована расширением браузера')
              }
            }
            this.$router.push({ name: 'master.payment.order', params: { orderUUID: response.uuid } })
          })
          .catch (this.notifyError)
      }
    }
  }

  private handleClose(sendMetrika = false) {
    if (this.courseID === 346 && sendMetrika) {
      try {
        this.$metrika.reachGoal('gobackbasemath')
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
    this.course = null
    this.localVisible = false
    this.$emit('close')
    this.$emit('update:courseID', null)
  }

  private handleChangeIndex(index: number) {
    this.packageId = this.previousPackages.get(this.selectedGroupIndex) ?? this.course?.groups[index].packages.find(_package => _package.isDefault)?.id ?? this.course?.groups[index].packages[0]?.id ?? 0
  }

  private setDefaultPackage(courseInfo?: PurchaseLargeResource) {
    this.selectedGroupIndex = this.groupTypeIndex

    const packages = courseInfo?.groups[this.selectedGroupIndex].packages
    const packagesWithDiscounts = courseInfo?.groups?.[this.selectedGroupIndex]?.discounts?.packagesWithDiscounts
    const defaultPackage = packages?.find(_package => _package.isDefault)
    const defaultPackageWithDiscount = packagesWithDiscounts?.find(_package => _package.isDefault)
    const firstPackageWithDiscount = packagesWithDiscounts?.[0]?.id
    this.packageId = defaultPackage?.id ?? defaultPackageWithDiscount?.id ?? packages?.[0]?.id ?? firstPackageWithDiscount ?? 0
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
    if (value) {
      this.fetchCourse(this.courseID)
    }
  }

  // Отправляем данные заказа
  // Так нужно, потому что при отсутствии авторизации
  // модалка будет закрыта и нам нужно сохранить
  // параметры заказа

  private updateOrder() {
    if(this.group) {
      this.$emit('update:orderInfo', {
        packageId: this.packageId,
        groupId: this.group.id,
      })
    }
  }

  @Watch('packageId')
  watchPackageId(newValue: number) {
    this.updateOrder()
    this.previousPackages.set(this.selectedGroupIndex, newValue)
  }

  @Watch('group')
  watchGroupId() {
    this.updateOrder()
  }
}
