















import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

// components
import CoursesList from '@/components/views/store/List.vue'
import CoursesMy from '@/components/views/courses/My.vue'

@Component({
  components: {
    CoursesList,
    CoursesMy,
  },
})
export default class Store extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Магазин курсов',
    }
  }

  private currentStorePage = 0
  private storeTabs = [
    {
      label: 'Мои курсы',
      value: 0,
    },
    {
      label: 'Магазин',
      value: 1,
    },
  ]

  // Можно и тернарником, но сделал таким способом, на случай добавления новых табов
  private get currentComponent() {
    if(this.currentStorePage === 0) return CoursesMy

    if(this.currentStorePage === 1) return CoursesList

    return null
  }
}
