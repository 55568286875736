



















































import { Bind, Debounce } from 'lodash-decorators'
import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import CoursePaymentModal from '@/components/modals/courses/CoursePaymentModal.vue'
import CourseStoreCard from '@/components/cards/CourseStoreCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import StoreBannerSlider from '@/components/StoreBannerSlider.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
import MasterCoursesModule from '@/store/modules/master/courses'
import MasterEducationModule from '@/store/modules/master/education'
// types
import { IMasterCoursesFilter, MasterCourseShortResource, SaleBannerResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
    CoursePaymentModal,
    CourseStoreCard,
    Select,
    StoreBannerSlider,
  },
})
export default class CoursesList extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  private get filter() {
    return MasterCoursesModule.coursesFilter
  }

  private set filter(filter: IMasterCoursesFilter) {
    MasterCoursesModule.setCoursesFilter(filter)
    this.filterCourses()
  }

  private selectedCourseID: number | null = null
  private showCourseModal = false
  private showBanners = true

  private discounts: SaleBannerResource[] = []

  private get courseTypes () {
    return DictionaryModule.courseTypes
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get courses (): MasterCourseShortResource[] {
    return MasterCoursesModule.allCourses
  }

  private get purchasedSubjects() {
    return MasterEducationModule.masterGroups.map(group => group.subject.value)
  }

  private innerCourses = this.courses

  private mounted() {
    this.fetchDiscounts()
  }

  private filterCourses() {
    this.innerCourses = this.courses
    Object.keys(this.filter).forEach(key => {
      if (key === 'hasDiscount' && this.filter[key]) {
        this.innerCourses = this.innerCourses.filter(course => course.hasDiscount)
      }
      if (key === 'subjectId' && this.filter[key]) {
        this.innerCourses = this.innerCourses.filter(course => course[key] === this.filter[key])
      }
      if (key === 'type' && this.filter[key]) {
        this.innerCourses = this.innerCourses.filter(course => course[key].value === this.filter[key])
      }
    })
  }

  private handleFilter(field: keyof IMasterCoursesFilter, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }
    if (value === null) {
      delete this.filter[field]
    }
  }

  private confirmBuy(course: MasterCourseShortResource) {
    this.confirm.open(
      'Покупка курса',
      '',
      {
        buttonConfirmText: 'Продолжить',
        isBuyCourse: true,
        skin: 'secondary',
      },
    )
      .then(() => {
        this.handleSelectCourse(course)
      })
      .catch(() => {return})
  }

  @Bind
  @Debounce(500)
  private fetchDiscounts() {
    MasterCoursesModule.fetchUsersDiscounts()
      .then(response => {
        this.discounts = response
      })
      .catch(this.notifyError)
      .finally(() => {
        this.fetchCourses()
      })
  }


  private fetchCourses () {
    MasterCoursesModule.fetchAllCourses()
      .then(() => {
        this.innerCourses = this.courses
        this.filterCourses()
      })
      .catch(this.notifyError)
  }

  private handleSelectCourse (course: MasterCourseShortResource) {
    this.selectedCourseID = course.id
    this.showCourseModal = true
  }

  private handleCloseBanners() {
    this.showBanners = false
    MasterCoursesModule.closeBanner()
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Магазин',
    }
  }
}
